import { isDefined } from "@clipboard-health/util-ts";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { useSession } from "@store/helperHooks";
import { Deploy } from "cordova-plugin-ionic";
import { CheckForUpdateResponse } from "cordova-plugin-ionic/dist/IonicCordova";
import { useCallback, useEffect, useState } from "react";

import { configureDeploy } from "./utils";

enum OtaState {
  IDLE = "idle",
  CHECKING = "checking",
  DOWNLOADING = "downloading",
  AVAILABLE = "available",
  NOT_AVAILABLE = "not-available",
}

export function useOtaUpdate() {
  const [otaUpdateInfo, setOtaUpdateInfo] = useState<CheckForUpdateResponse>();
  const [otaUpdateProgress, setOtaUpdateProgress] = useState(0);
  const [otaState, setOtaState] = useState<OtaState>(OtaState.IDLE);

  const { agent } = useSession();

  const checkForOtaUpdate = useCallback(async () => {
    setOtaState(OtaState.CHECKING);

    await configureDeploy();
    const updateInfo = await Deploy.checkForUpdate();

    if (updateInfo?.available) {
      setOtaState(OtaState.DOWNLOADING);
      logEvent(USER_EVENTS.OTA_UPDATE_DOWNLOAD_STARTED);

      const startTime = new Date();
      await Deploy.downloadUpdate((progress) => {
        if (progress && progress % 5 === 0) {
          setOtaUpdateProgress(progress);
        }
      });

      await Deploy.extractUpdate();
      const endTime = new Date();
      logEvent(USER_EVENTS.OTA_UPDATE_DOWNLOADED, {
        buildId: updateInfo.build,
        downloadTimeSeconds: (endTime.getTime() - startTime.getTime()) / 1000,
      });
      setOtaUpdateInfo(updateInfo);
      setOtaState(OtaState.AVAILABLE);
    } else {
      setOtaState(OtaState.NOT_AVAILABLE);
    }
  }, []);

  useEffect(() => {
    if (isDefined(agent?.userId) && otaState === OtaState.IDLE && isCapacitorPlatform()) {
      // downloaded build will be auto applied when worker reopens the app
      checkForOtaUpdate();
    }
  }, [agent?.userId, checkForOtaUpdate, otaState]);

  return {
    otaUpdateInfo,
    otaUpdateProgress,
    otaState,
  };
}
